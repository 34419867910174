<template>
  <div class="card rounded-8">
    <h1 class="text-center text-39 leading-87">{{ title }}</h1>

    <ul class="columns flex justify-around">
      <li v-for="(column, index) in columns" :key="index" class="flex-1">
        <div class="icon flex justify-center items-center mb-16">
          <img :src="column.icon.src" class="flex-none" alt="icon" />
        </div>
        <h2 class="font-bbva text-24 font-medium leading-142 text-center color-grey-900 mb-16">
          {{ column.title }}
        </h2>
        <!-- prettier-ignore -->
        <div class="leading-300 text-18 whitespace-nowrap whitespace-pre text-center">{{ column.elements }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppColumnsCard',

  props: {
    title: String,
    columns: Array,
  },
}
</script>

<style lang="scss" scoped>
.card {
  padding: 56px 64px 48px;
  box-shadow: 0 4px 8px 1px theme('colors.grey.300');

  .columns {
    margin-top: 56px;
  }

  .icon > img {
    max-width: 133px;
    max-height: 120px;
  }
}
</style>
